import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

import "../scss/custom.scss"

const DisclaimerPage = () => (
  <Layout>
    <SEO title="Disclaimer" />
    <div className="container" style={{marginTop: 36}}>
      <h4>Jentic 免責聲明：</h4>
      <p style={{paddingTop: 12}}>本公司將依照個人資料保護法相關規定保護你的個人資料，並遵守Jentic Ltd的私隱保護政策。 如有任何爭議，商戶將保留最終決定權而毋須另行通知。因實際情況所限，Jentic Ltd與各門市銷售價格、 促銷活動、條件與限制有機會各為不同，一切條款只適用於Jentic Ltd。Jentic Ltd並非商品或服務的賣方或供應商。Jentic Ltd負責管理網站、安排訂單處理過程及完成提供閣下透過Jentic Ltd向供應商訂購的商品或服務。 透過Jentic Ltd購買的商品或服務須受商戶的條款及細則約束。對銷售及處理索賠或因閣下透過Jentic Ltd購買任何商品、 服務或優惠卷可能直接或間接蒙受索賠，損失或損害的責任，不論如何造成，（包括遺漏或疏忽）， 我們也不接受因使用或依據透過Jentic Ltd獲取的信息的任何索賠、損失或損害承擔任何責任。 確保透過Jentic Ltd.獲得的任何產品，服務或信息滿足閣下的特定要求是閣下的全部責任。 特別是醫療保健/美容等服務，閣下務必注意，在決定購買前應確保瞭解該服務將如何進行。 如有疑問，閣下應諮詢專業顧問。</p>
      <div style={{textAlign: "center", marginBottom: 24}}>
        <Link to="/">回到主頁</Link>
      </div>
    </div>

  </Layout>
)

export default DisclaimerPage
